import { useFetch } from '@/client.js'

class EmployeesService {
  get = () => useFetch('employees').get().json()
  getSchedule = (date) => useFetch(`employees/schedule?d=${date}`).get().json()
  create = (employee) => useFetch('employees').post(employee).json()
  uploadPhoto = (id, data) => useFetch(`employees/${id}/photo`).options({ headers: { 'Content-Type': 'multipart/form-data' } }).post(data)
  getById = (id) => useFetch(`employees/${id}`).get().json()
  update = (employee) => useFetch(`employees/${employee.id}`).put(employee)
  patch = (employee, position) => useFetch(`employees/${employee.id}`).patch({ position: position })
  delete = (id) => useFetch(`employees/${id}`).delete()
  saveSchedule = (schedule) => useFetch(`employees/${schedule.employee.id}/schedule${schedule.id !== null ? `/${schedule.id}` : ''}`).put(schedule)
  deleteSchedule = (schedule) => useFetch(`employees/${schedule.employee.id}/schedule/${schedule.id}`).delete()
  changePassword = (currentPassword, newPassword, id) => useFetch(`employees/${id}/password`).post({
    current: currentPassword,
    new: newPassword
  })
}

export default new EmployeesService()