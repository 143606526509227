import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import { setupRouter } from './router'
import { setupI18n } from '@/i18n.js'
import en from './locales/en.json'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { createHead } from 'unhead'
import { createGtm } from '@gtm-support/vue-gtm'

export const app = createApp(App)

const i18n = setupI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en
  }
})

const router = setupRouter(i18n)

if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    app,
    dsn: 'https://6adc95aed05cc25ceff52733858e3c38@o4504814771503104.ingest.us.sentry.io/4507266062417920',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api2\.appointr\.app\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  const gtm = createGtm({
    id: 'GTM-N8NH8NL',
    enabled: true
  })
  app.use(gtm)
}

createHead()

app.use(createPinia())
app.use(i18n)
app.use(router)

app.mount('#app')

useRegisterSW({
  onRegistered (r) {
    r && setInterval(() => {
      r.update()
    }, 1800000)
  }
})